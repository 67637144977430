<template>
  <v-container>
    <v-form @submit.prevent="register">
      <v-text-field v-model="username" label="用户名"></v-text-field>
      <v-text-field v-model="password" label="密码" type="password"></v-text-field>
      <v-text-field v-model="inviteCode" label="邀请码" readonly></v-text-field>
      <v-btn type="submit" color="primary">注册并登录</v-btn>
    </v-form>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
        {{ snackbar.text }}
        <v-btn text @click="snackbar.show = false">关闭</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: "Register",
  data() {
    return {
      snackbar: {
        show: false,
        color: '',
        text: ''
      },
      username: '',
      password: '',
      inviteCode: ''
    }
  },
  created() {
    // 从路由参数中获取邀请码
    this.inviteCode = this.$route.params.code;
  },
  methods: {
    register() {
      // 提交注册信息
      const params = new URLSearchParams();
      params.append('name', this.username);
      params.append('password', this.password);
      params.append('invitationCode', this.inviteCode);

      axios.post('https://qidafang.com/wikiapi/register', params)
      .then(response => {
        this.snackbar.show = true;
        this.snackbar.color = 'success';
        this.snackbar.text = '注册成功！';
        this.$store.dispatch('login', {
          username: this.username,
          password: this.password
        });

        setTimeout(() => {
          this.$router.push('/vote/intelligence');
        }, 1000);
      })
      .catch(error => {
        this.snackbar.show = true;
        this.snackbar.color = 'error';
        this.snackbar.text = error.response.data.message;
      });
    }
  }
};
</script>

<style scoped>
</style>
